import { http } from '@/http/axios.js'

// 列表
export function getListAPI(params) {
  return http({
    url: `/manage/group/list`,
    method: 'get',
    params
  })
}

export function delAPI(id) {
  return http({
    url: `/manage/group/delete?id=${id}`,
    method: 'post',
    data: {}
  })
}
export function addAPI(data) {
  return http({
    url: `/manage/group/create`,
    method: 'post',
    data
  })
}

export function editAPI(data) {
  return http({
    url: `/manage/group/update`,
    method: 'post',
    data
  })
}

export function getDetailAPI(id) {
  return http({
    url: `/manage/group/detail`,
    method: 'get',
    params: { id }
  })
}
export function reviewAPI(data) {
  return http({
    url: `/manage/group/audit`,
    method: 'post',
    data
  })
}

<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="名称">
          <el-input style="width: 200px" clearable v-model="searchData.name" size="mini" placeholder="请输入"></el-input>
        </el-form-item>

        <el-form-item label=" ">
          <el-button type="primary" size="mini" icon="el-icon-search" @click="getList">搜索</el-button>
          <el-button type="info" size="mini" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tabs">
      <div :class="['item', searchData.status === -1 ? 'active' : '']" @click="handleClickTab(-1)">
        <el-badge>
          <span class="tab-name">全部</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 0 ? 'active' : '']" @click="handleClickTab(0)">
        <el-badge>
          <span class="tab-name">待审核</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 1 ? 'active' : '']" @click="handleClickTab(1)">
        <el-badge>
          <span class="tab-name">已通过</span>
        </el-badge>
      </div>
      <div :class="['item', searchData.status === 5 ? 'active' : '']" @click="handleClickTab(5)">
        <el-badge>
          <span class="tab-name">已拒绝</span>
        </el-badge>
      </div>
    </div>
    <!-- <el-button style="margin-bottom: 10px" icon="el-icon-plus" plain type="primary" size="mini" @click="handleAdd">新增</el-button> -->
    <el-table :data="list">
      <el-table-column prop="name" label="名称" width="width"> </el-table-column>
      <!-- <el-table-column prop="icon" label="图标" width="width">
        <template slot-scope="{ row }">
          <el-image style="width: 40px; height: 40px; border-radius: 5px" :preview-src-list="[row.icon]" :src="row.icon" fit="cover">
            <div style="line-height: 40px; text-align: center; background: #f5f7fa" slot="error">无</div>
          </el-image>
        </template>
      </el-table-column> -->
      <el-table-column prop="icon" label="状态" width="width">
        <template slot-scope="{ row }">
          <el-tag v-if="row.status == 0" type="warning" size="small">{{ row.statusName }}</el-tag>
          <el-tag v-if="row.status == 1" type="primary" size="small">{{ row.statusName }}</el-tag>
          <el-tag v-if="row.status == 5" type="danger" size="small">{{ row.statusName }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="describe" label="描述" width="width" min-width="150px"> </el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="width" min-width="130px"> </el-table-column>
      <el-table-column label="操作" width="120" fixed="right">
        <template slot-scope="{ row }">
          <el-link v-if="row.status == 0" style="margin-right: 10px" type="primary" :underline="false" @click="handleReview(row)">审核</el-link>
          <!-- <el-link style="margin-right: 10px" type="primary" :underline="false" @click="handleEdit(row)">编辑</el-link>
          <el-link :underline="false" type="danger" @click="handleDel(row)">删除</el-link> -->
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.pagesize" @pagination="getList" />
    <!-- 新增编辑 -->
    <el-dialog :title="formData.id ? '编辑' : '新增'" :visible.sync="show_update" width="width">
      <el-form ref="form" :model="formData" label-width="80px">
        <el-form-item label="名称" prop="name" :rules="rules">
          <el-input style="width: 250px" size="small" v-model="formData.name" placeholder="请输入" clearable></el-input>
        </el-form-item>
        <el-form-item class="icon-box" label="图标" prop="icon" :rules="rules">
          <UploadImg :url.sync="formData.icon"></UploadImg>
        </el-form-item>
        <el-form-item label="描述">
          <el-input style="width: 250px" type="textarea" placeholder="请输入" v-model="formData.describe"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="show_update = false" size="small">取 消</el-button>
        <el-button type="primary" @click="submit" size="small">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 审核 -->
    <el-dialog title="审核" :visible.sync="show_review" width="width">
      <el-form ref="formReview" :model="formData" label-width="120px">
        <el-form-item label="审核状态：">
          <el-radio-group v-model="reviewData.status">
            <el-radio :label="1">通过</el-radio>
            <el-radio :label="5">拒绝</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="show_review = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="submitReview" size="mini">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getListAPI, delAPI, addAPI, editAPI, getDetailAPI, reviewAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
import UploadImg from '@/components/qiniu-uploader/uploader-img.vue'
export default {
  name: 'Index',
  mixins: [authBtnMixin],
  components: { UploadImg },
  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      show_update: false,
      show_review: false,
      list: [],
      searchData: {
        page: 1,
        pagesize: 10,
        status: -1
      },
      total: 0,
      formData: {
        name: '',
        icon: '',
        describe: ''
      },
      reviewData: {
        id: '',
        status: 1
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },

  activated() {
    this.getList()
  },
  mounted() {},

  methods: {
    handleReview({ id }) {
      this.reviewData = {
        id,
        status: 1
      }
      this.show_review = true
    },
    submitReview() {
      reviewAPI(this.reviewData).then(() => {
        this.$message.success('提交成功')
        this.getList()
        this.show_review = false
      })
    },
    handleClickTab(val) {
      if (this.searchData.status == val) return
      this.searchData.status = val
      this.getList()
    },
    async getList() {
      let params = { ...this.searchData }
      if (params.status == -1) {
        params.status = ''
      }
      const res = await getListAPI(params)
      this.list = res.records || []
      this.total = res.totalRow || 0
    },
    // del
    async handleDel({ id }) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAPI(id).then(() => {
            this.$message.success('删除成功')
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 新增
    handleAdd() {
      this.show_update = true
      this.$nextTick(() => {
        this.formData = {
          name: '',
          icon: '',
          describe: ''
        }
        this.$refs.form.resetFields()
      })
    },
    // edit
    async handleEdit({ id }) {
      this.show_update = true
      this.$nextTick(async () => {
        this.formData = await getDetailAPI(id)
        this.$refs.form.resetFields()
      })
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            editAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.getList()
              this.show_update = false
            })
          } else {
            addAPI(this.formData).then(() => {
              this.$message.success('添加成功')
              this.getList()
              this.show_update = false
            })
          }
        }
      })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 35px;
      }
    }
  }
  .tabs {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdfe6;
    .item {
      cursor: pointer;
      position: relative;
      padding-bottom: 5px;
      margin-right: 20px;
      // border-bottom: 2px solid #409eff;

      .tab-name {
        padding: 6px;
      }
    }
    .active {
      color: #409eff;
      font-weight: 600;
      &::after {
        content: '';
        display: inline-block;
        height: 2px;
        width: 25px;
        background: #409eff;
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 5px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  /* switch按钮样式 */
  .switch .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }
  /*打开时文字位置设置*/
  .switch .el-switch__label--right {
    z-index: 1;
  }
  /* 调整打开时文字的显示位子 */
  .switch .el-switch__label--right span {
    margin-left: 5px;
    margin-right: 10px;
  }
  /*关闭时文字位置设置*/
  .switch .el-switch__label--left {
    z-index: 1;
  }
  /* 调整关闭时文字的显示位子 */
  .switch .el-switch__label--left span {
    margin-left: 20px;
  }
  /*显示文字*/
  .switch .el-switch__label.is-active {
    display: block;
  }
  /* 调整按钮的宽度 */
  .switch.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 70px !important;
    margin: 0;
  }
}
</style>
<style lang="scss" scoped>
.icon-box /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 108px;
    height: 108px;
    line-height: 108px;
    text-align: center;
  }
  .avatar {
    width: 108px;
    height: 108px;
    display: block;
  }
}
</style>
